.capacity-grid-container {
    width: 100%;
    overflow-x: scroll;
}

.capacity-grid td {
    width: 3.5em;
}

td.capacity-date-monday {
    border-left-color: gray;
    border-left-style: dashed;
    border-left-width: 1px;
}

.capacity-pm {
    background-color: black;
    color: white;
}

.capacity-ba {
    background-color: cadetblue;
    color: white;
}

.capacity-sa {
    background-color: darkslateblue;
    color: white;
}

.capacity-tooltip {
    /* position: relative;
    display: inline-block; */
}
  
.capacity-tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: white;
    color: black;
    text-align: center;
    border-radius: 6px;
    border-color: black;
    border-width: 1px;
    border-style: dashed;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
}
  
.capacity-tooltip:hover .tooltiptext {
    visibility: visible;
}