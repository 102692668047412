.gantt-grid-container {
    width: 100%;
    overflow-x: scroll;
}

/* .gantt-grid td {
    width: 3.5em;
} */

td.gantt-date-monday {
    border-left-color: gray;
    border-left-style: dashed;
    border-left-width: 1px;
}

td.calendar-gantt-user {
    text-align: right;
}

.gantt-vac {
    background-color: blue;
    color: white;
}

.gantt-work {
    background-color: black;
    color: white;
}

.gantt-mixed {
    background-color: blue;
    color: red;
}

.gantt-reviewer {
    background-color: lightgray;
    color: white;
}


.gantt-tooltip {
    /* position: relative;
    display: inline-block; */
}
  
.gantt-tooltip .tooltiptext {
    visibility: hidden;
    width: 340px;
    background-color: white;
    color: black;
    font-size: small;
    text-align: left;
    border-radius: 6px;
    border-color: black;
    border-width: 1px;
    border-style: dashed;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
}
  
.gantt-tooltip:hover .tooltiptext {
    visibility: visible;
}