.deals .card, .pool .card {
    margin-top: 0.5rem !important;
    margin-left: 0.25rem !important;
}

.deals .card .card-body,
.deals .card .card-header,
.pool .card .card-body,
.pool .card .card-header {
    padding: 0.5rem !important;
}

.pool .card-middle-header {
    border-top: 1px solid rgba(0, 0, 0, 0.125)
}

.deals-wrapper {
    height:calc(100vh - 4em);
    overflow: scroll;
}

.deal-selected .card-header {
    background-color: #17a2b8!important;
    color: #fff!important;
}

.deal-over .card-header {
    background-color: #28a745!important;
    color: #fff!important;
}


.deal-details-actions {
    padding: 1em
}

.deal-details .dropdown {
    display: inline
}

.edit-deal .octicon-pencil {
    cursor: pointer;
}

.deal-details .octicon-x {
    cursor: pointer;
}

.add-tag .octicon-plus {
    cursor: pointer;
    padding-left: 5px;
}

.user-component-icon-wrapper {
    position: relative;
}

.user-component-busy-icon {
    position: absolute;
    bottom: 0;
    right: 0;
}

.user-component .person-icon {
    margin-right: 0.2em
}

.user-component-type-icon-topleft {
    position: absolute;
    left: -0.5em;
    top: -1em;
}

.user-component-type-icon-topright {
    position: absolute;
    left: 1.2em;
    top: -1em;
}

.user-component .user-on-vacation .mega-octicon {
    color: #6c757d
}

.user-component-on-vacation-icon {
    position: absolute;
    left: 0;
}

.user-component-on-vacation-icon .es {
    
}

.main-toolbar .main-menu {
    margin-right: 0.5em;
    display: inline-block;
}

.deal-details .user-component-no-delete .user-component-icon-wrapper {
    margin-left: 30px;
}

table.commands-queue td {
    margin: 1em
}

.comment-icon {
    position: absolute;
    right: 10px;
    top: 5px;
}

.badge-qualification {
    color: white;
    background-color: #000080;
 }


/* font */
@font-face{font-family:'es';src:url('fonts/e3suiFont.woff')format('woff');font-weight:normal;font-style:normal}
.es{font:normal normal normal 15px/1 es;text-rendering:auto;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;speak:none}
[class*="es-m-"]{font-size:30px}
[class*="es-l-"]{font-size:45px}
.es-star-circle:before{content:"\e924"}
.es-calendar-epam:before{content:"\e923"}
.es-calendar-it-week:before{content:"\e922"}
.es-calendar-feedback:before{content:"\e926"}
.es-calendar-engagement-survey:before{content:"\e920"}
.es-calendar-compensation:before{content:"\e91f"}
.es-calendar-assessment:before{content:"\e925"}
.es-m-shield:before{content:"\e91d"}
.es-check:before{content:"\e91c"}
.es-skype-personal:before{content:"\e91b"}
.es-expand:before{content:"\e91a"}
.es-collapse:before{content:"\e919"}
.es-plus-bordered:before{content:"\e918"}
.es-minus-bordered:before{content:"\e917"}
.es-draggable:before{content:"\e916"}
.es-cross:before{content:"\e915"}
.es-chevron-up:before{content:"\e914"}
.es-chevron-down:before{content:"\e913"}
.es-minus:before{content:"\e912"}
.es-gear:before{content:"\e911"}
.es-m-arrow-top-left:before{content:"\e910"}
.es-person:before{content:"\e90f"}
.es-chevron-right-inverse:before{content:"\e90e"}
.es-m-trash:before{content:"\e90d"}
.es-m-pencil:before{content:"\e90c"}
.es-m-lock-opened:before{content:"\e90b"}
.es-m-lock-closed:before{content:"\e90a"}
.es-m-help:before{content:"\e909"}
.es-m-flip:before{content:"\e908"}
.es-pin:before{content:"\e907"}
.es-balloon:before{content:"\e906"}
.es-skype:before{content:"\e905"}
.es-phone:before{content:"\e904"}
.es-ellipsis:before{content:"\e903"}
.es-m-looking-glass:before{content:"\e902"}
.es-m-looking-glass-plus:before{content:"\e901"}
.es-m-chain:before{content:"\e900"}
.es-telescope-logo:before{content:"\e80a"}
.es-bell:before{content:"\e809"}
.es-letter:before{content:"\e808"}
.es-looking-glass:before{content:"\e807"}
.es-chevron-right:before{content:"\e806"}
.es-hamburger:before{content:"\e805"}
.es-arrow-right-b:before{content:"\e804"}
.es-l-briefcase:before{content:"\e803"}
.es-m-chevron-right:before{content:"\e802"}
.es-m-chevron-left:before{content:"\e801"}
.es-parent-arrow-up-down:before{content:"\e800"}
.es-plus:before{content:"\e611"}
.es-away:before{content:"\e610"}
.es-buggy:before{content:"\e60f"}
.es-heroes-tiny:before{content:"\e60e"}
.es-lock:before{content:"\e60d"}
.es-ban:before{content:"\e60c"}
.es-cup:before{content:"\e60b"}
.es-parent-arrow:before{content:"\e60a"}
.es-item-overdue:before{content:"\e609"}
.es-item-open:before{content:"\e608"}
.es-item-done:before{content:"\e607"}
.es-medkit:before{content:"\e606"}
.es-trophy:before{content:"\e605"}
.es-plane:before{content:"\e604"}
.es-beach:before{content:"\e603"}
.es-star:before{content:"\e602"}
.es-birthday:before{content:"\e601"}
.es-gift:before{content:"\e600"}
.ii-m-shield:before{content:"\e900"}
.ii-m-help:before{content:"\e901"}
.ii-m-gear:before{content:"\e902"}
.ii-m-flip:before{content:"\e903"}
.ii-m-pin:before{content:"\e904"}
.es-snowflake{display:inline-block;font:normal normal normal 14px/1 FontAwesome !important;font-size:inherit;text-rendering:auto;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}
.es-snowflake::before{content:"\f2dc"}